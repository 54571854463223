import React, { useState, useEffect, useRef } from "react";
import PulseLoader from "../PulseLoader";
import User_icon from "../../assets/icons/svg/user_icon.png";
import ChatMRO from "../../assets/icons/svg/favicon.png";
import Product_dummy from "../../assets/icons/svg/Product_dummy.png";
import Cart from "../../assets/icons/svg/cart_icon.svg";
import Pro_green from "../../assets/icons/svg/Image 6.png";
import Pro_yellow from "../../assets/icons/svg/Image 7.png";
import Pro_blue from "../../assets/icons/svg/Image 8.png";
import Pro_red from "../../assets/icons/svg/Image 9.png";
import ThumbsUpIcon from "../../assets/icons/like.png";
import ThumbsDownIcon from "../../assets/icons/dislike.png";
import OkIcon from "../../assets/icons/feedback.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import DOMPurify from 'dompurify';
import { sendFeedback } from "../../services/sendFeedback"
import { marked } from 'marked';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

const Index = ({ conversation = [], isFetchingAnswer, currentPrompt, developerMode }) => {
  // const [developerMode, setDeveloperMode] = useState(false)
  const chatContainerRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);

  // Check if the user is near the bottom of the container
  const isNearBottom = () => {
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;    
    return scrollHeight - scrollTop <= clientHeight + 100; // Adjust buffer as needed
  };
  
  // Effect to handle auto-scrolling
  useEffect(() => {
    // If the user is not actively scrolling or is near the bottom, auto-scroll
    if (!isUserScrolling || isNearBottom()) {      
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [conversation, isFetchingAnswer]);

  // Handle scroll events
  const handleScroll = () => {
    // Set a timeout to reset the scroll state
    setIsUserScrolling(true);
    // If the user scrolls to the bottom, allow auto-scrolling again
    if (isNearBottom()) {
      setIsUserScrolling(false);
    }
  };

  return (

    <div className="main-box" ref={chatContainerRef} onScroll={handleScroll} style={{ overflowY: 'auto', maxHeight: '100vh' }}>
      <div className="fixed-toggle-container">
      {/* <p>Logged in acount {email}</p>
      <RecentChats 
        email={email} 
        setSessionId={setSessionId} 
        setConversation={setConversation} 
      /> */}
        {/* <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="developerModeSwitch"
            checked={developerMode}
            onChange={toggleDeveloperMode}
          />
          <label className="form-check-label" htmlFor="developerModeSwitch">
            {developerMode ? 'Developer Mode' : 'Developer Mode'}
          </label>
        </div> */}
      </div>
      {conversation && conversation.length > 0 ? (
        conversation.map((message) => (
          <AnswerBox
            key={message.id}
            user={message.user}
            prompt={message.prompt}
            answer={message.answer}
            backendData={message.backendAnswer}
            isAnswerFromThisChat={message.isAnswerFromThisChat}
            showDeveloperData={developerMode}
          />
        ))
      ) : isFetchingAnswer === false ? (
        <NoConversationBox />
      ) : null}
      {isFetchingAnswer && (
        <AnswerBox
          user={"User"}
          prompt={currentPrompt}
          isFetchingAnswer={isFetchingAnswer}
        />
      )}
    </div>
  );
};
export default Index;

const AnswerBox = ({
  user,
  prompt,
  answer,
  backendData,
  isFetchingAnswer,
  isAnswerFromThisChat,
  showDeveloperData
}) => {
  const [count, setCount] = useState(0);

  const [typedText, setTypedText] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [feedback, setFeedback] = useState(null); // State to track the clicked feedback button
  const [showFeedback, setShowFeedback] = useState(false);
  const [textFeedback, setTextFeedback] = useState('');
  const [showInputBox, setShowInputBox] = useState(false);
  useEffect(() => {
    if (typeof answer === "string") {
      let currentIndex = 0,
        interval;

      if (isAnswerFromThisChat) {
      
        const words = answer.split(' ');
        // setIsLoading(true);  // Start loader
        setIsTyping(true);   // Start typing effect
        interval = setInterval(() => {
          if (currentIndex <= words.length) {
            setShowFeedback(true);
            // **Build up the typedText word by word**
            setTypedText(words.slice(0, currentIndex).join(' '));
            currentIndex++;
          } else {
            clearInterval(interval);
            setIsTyping(false);   // Typing ends
            // setIsLoading(false);  // Loader stops
            setShowFeedback(true); // Show feedback buttons
          }
        }, 100); // **Adjust the interval duration as needed**
      } else {
        setTypedText(answer);
        setIsLoading(false);
        setShowFeedback(true);
      }

      return () => clearInterval(interval);
    } else {
      setTypedText(answer);
      setIsLoading(false);   // Loader off
      setIsTyping(false);    // Typing off
      setShowFeedback(true); // Feedback enabled
    }
  }, [answer, isFetchingAnswer]);


  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    setCount(count - 1);
  };
  const renderBackendData = () => (
    <div className="developer-info" style={{ display: showDeveloperData ? 'block' : 'none' }}>
      <pre>{JSON.stringify(backendData, null, 2)}</pre>
    </div>
  );

  const cleanHTML = (html) => {
    // Remove excessive <br> tags (e.g., more than two consecutive <br>)
    // let text = "Here are the specifications for the item with MPN 3PAK:- **Max. Cable Diameter**: 1 inch- **Length**: 38 1/2 inches- **Width**: 10 3/4 inches- **Height**: 1 1/2 inches- **Load Capacity**: 2,100 lb/Axle- **Brand**: Fastlane- **Connection Style**: L ShapedThese drop-over modular cable ramps are designed to protect cables and hoses from foot and vehicle traffic.<br/> They have an interlocking design which allows for reconfigurations, repairs, or replacements without moving the cable or hose.<br/>Source: grainger_catalog-1-500.pdf【4:1†source】."
    // let sampleToHtml = marked.parse(text);
    let markdownToHtml = marked.parse(html);
  
    // Add <html> and </html> tags
    let htmlWithTags = `<html>${markdownToHtml}</html>`;

    // Remove excessive <br> tags (e.g., more than two consecutive <br>)
    let cleanedHTML = htmlWithTags.replace(/(<br\s*\/?>\s*){2,}/g, '<br><br>');

    // Replace occurrences of ': ' with a space
    cleanedHTML = cleanedHTML.replace(/:\s+/g, ' ');

    // Optionally remove other unwanted tags or characters
    cleanedHTML = cleanedHTML.replace(/<!DOCTYPE\s+><br><>/g, '');

    // Sanitize the cleaned HTML
    return DOMPurify.sanitize(cleanedHTML, { USE_PROFILES: { html: true } });
  };

  const handleFeedback = async (type, textFeedback = '') => {
    setFeedback(type)    
    setShowInputBox(false);
 
    console.log(`User feedback: ${type}`);

    const feedback_data ={
      "session_id" : backendData.session_id,
      "query" : backendData.query,
      "answer_data" : answer,
      "all_data" : backendData,
      "feedback" : type === 'ok' ? textFeedback : type,
    };
    try {
      sendFeedback(feedback_data);
      console.log('Feedback successfully sent!');
    } catch (error) {
      console.error('Error sending feedback:', error);
    }

    
    const fpPromise = import('https://openfpcdn.io/fingerprintjs/v4').then(FingerprintJS => FingerprintJS.load())

    // Get the visitor identifier when you need it.
    fpPromise
      .then(fp => fp.get())
      .then(result => {
        // This is the visitor identifier:
        const visitorId = result.visitorId
        console.log(visitorId)
      })

    // Handle the feedback logic here, e.g., sending it to the backend
  };
  const renderAnswer = () => {
    if  (typeof typedText === "string") {      
      // if (answer.startsWith("<html>") && answer.endsWith("</html>")) {
        const cleanContent = cleanHTML(typedText);
        // return DOMPurify.sanitize(answer, { USE_PROFILES: { html: true } });

        return <div className="chat-response" dangerouslySetInnerHTML={{ __html: cleanContent }} />;
      // }
    }    
  };
  const handleSubmit = (e) => {
    e.preventDefault();    
    setShowInputBox(false);
    handleFeedback('ok', textFeedback);
  };

  const handleButtonClick = (type) => {
    if (type === 'ok') {
      setShowInputBox(true);
      setFeedback(type);
    } 
    else {
      setFeedback(type);
      setShowInputBox(false);
      handleFeedback(type);
    }
  };

  return (
    <div className="chat-container">
      <div>
        <div className="chat-box">
          <div className="chat-icon">
            <img
              src={User_icon}
              alt="user_icon"
              width={"32px"}
              height={"32px"}
            />
          </div>
          <div>
            <div className="font-bold">{user}</div>
            <div className="prompt-bold">{prompt}</div>
          </div>
        </div>
        <div className="chatMRO-Icon ">
          <div className="chat-MRO">
            <img
              src={ChatMRO}
              alt="chatMRO icon"
              width={"34px"}
              height={"34px"}
            />
          </div>
          <div>
            <div className="font-bold">ChatMRO</div>
            {isFetchingAnswer  ? (
              <PulseLoader />
            ) : (
              <div className="answer-bold">
                <div className="backend-answer">
                  {renderBackendData()}

                </div>                            
                {renderAnswer()}                    
              </div>
            )}
            {!isTyping && showFeedback && (
            <div className="feedback-buttons">
                <button
              onClick={() => handleFeedback('thumbs_up')}
              style={{
                backgroundColor: feedback === 'thumbs_up' ? '#6aa84f' : 'initial',
              }}
            >
              <img src={ThumbsUpIcon} alt="Thumbs Up" />
            </button>
            <button
              onClick={() => handleFeedback('thumbs_down')}
              style={{
                backgroundColor: feedback === 'thumbs_down' ? '#cc0001' : 'initial',
              }}
            >
              <img src={ThumbsDownIcon} alt="Thumbs Down" />
            </button>
            <button
              onClick={() =>handleButtonClick('ok')}
              style={{
                backgroundColor: feedback === 'ok' ? '#4897d9' : 'initial',
              }}
            >
              <img src={OkIcon} alt="Ok" />
            </button>
          </div>
          )}
        {showInputBox && (
        <form onSubmit={handleSubmit} className="feedback-input-form">
          <input
            type="text"
            className="form-control"
            id="feedbackInput"
            value={textFeedback}
            onChange={(e) => setTextFeedback(e.target.value)}
            placeholder="Enter your feedback"
            required
          />
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};


const NoConversationBox = () => (
  <div className="input-container">
    <div className="heading">
      <h2 className="hello">Hello,</h2>
      <div className="caption">
        <h2>How can I help you today?</h2>
      </div>
    </div>
    <div className="library-box">
      <div className="button-group">
        {/* <button className="button"> */}
          {/* <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path fill="currentColor" fill-rule="evenodd" d="M13.997 3.39A2.5 2.5 0 0 1 17.2 2.103l2.203.882a2.5 2.5 0 0 1 1.342 3.369L19.063 10H20a1 1 0 0 1 1 1v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-8a1 1 0 0 1 .992-1l-.149-.101-.03-.022c-1.254-.924-1.016-2.864.425-3.458l2.12-.874.724-2.176c.492-1.479 2.41-1.851 3.42-.665L11.99 4.45l1.521.01zm1.513 1.506a2 2 0 0 1 .461 2.618l-1.144 1.861v.045a1.3 1.3 0 0 0 .044.278 1 1 0 0 1 .047.302h1.942l2.07-4.485a.5.5 0 0 0-.268-.673l-2.203-.882a.5.5 0 0 0-.641.258zM12.889 10a3.3 3.3 0 0 1-.06-.499c-.01-.236-.004-.69.237-1.081l1.202-1.954-2.293-.016a2 2 0 0 1-1.51-.704L8.98 4l-.725 2.176A2 2 0 0 1 7.12 7.394L5 8.267l2.063 1.407c.129.087.23.2.303.326zM5 12v7a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-7zm4.5 2.5a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1" clip-rule="evenodd"></path>
          </svg> */}
          <div className="library-text button">I want to buy 1000 lbs capacity drum lifter, material steel</div>
        {/* </button> */}
        {/* <button className="button"> */}
          {/* <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19a3 3 0 1 1-6 0M15.865 16A7.54 7.54 0 0 0 19.5 9.538C19.5 5.375 16.142 2 12 2S4.5 5.375 4.5 9.538A7.54 7.54 0 0 0 8.135 16m7.73 0h-7.73m7.73 0v3h-7.73v-3"></path>
          </svg> */}
          <div className="library-text button">Help me find an alternate of AERH36</div>
        {/* </button> */}
      </div>
      <div className="button-group">
        {/* <button className="button"> */}
          {/* <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path fill="currentColor" fill-rule="evenodd" d="M12.455 4.105a1 1 0 0 0-.91 0L1.987 8.982 1.077 7.2l9.56-4.877a3 3 0 0 1 2.726 0l9.56 4.877A1.98 1.98 0 0 1 24 9.22V15a1 1 0 1 1-2 0v-3.784l-2.033.995v4.094a3 3 0 0 1-1.578 2.642l-4.967 2.673a3 3 0 0 1-2.844 0l-4.967-2.673a3 3 0 0 1-1.578-2.642v-4.094l-2.927-1.433C-.374 10.053-.39 7.949 1.077 7.2l.91 1.782 9.573 4.689a1 1 0 0 0 .88 0L22 8.989v-.014zM6.033 13.19v3.114a1 1 0 0 0 .526.88l4.967 2.674a1 1 0 0 0 .948 0l4.967-2.673a1 1 0 0 0 .526-.88V13.19l-4.647 2.276a3 3 0 0 1-2.64 0z" clip-rule="evenodd"></path>
          </svg> */}
          <div className="library-text button">Suggest gloves to prevent hand injuries for my food processing workforce.</div>
        {/* </button> */}
        {/* <button className="button"> */}
          {/* <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9.65 13.026-3.287 1.19A2 2 0 0 1 3.8 13.027l-.342-.934.597-1.275L1.75 7.419l2.348-.85 2.564 1.484a2 2 0 0 0 1.689.15l8.512-3.083c.291-.106.603-.142.912-.107l2.833.325a1.842 1.842 0 0 1 .422 3.565l-5.276 1.911m.598-1.275L13 14.5l-2.817 1.02-.343-3.622"></path>
            <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M3 19h18"></path>Suggest gloves to prevent hand injuries for my food processing workforce.

          </svg> */}
          <div className="library-text button">How to use hydraulic pumps?</div>
        {/* </button> */}
      </div>
    </div>

  </div>
);
