// D:\chatmro_frontend\chamro_website\src\Components\OverlayMessage.js
import React, { useState } from 'react';
import "../Overlay/overlay.css";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode as jwt_decode } from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
<link
  rel="stylesheet"
  href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
    const OverlayMessage = ({ onPasswordSubmit }) => {
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState("");
    const anotherPassword = process.env.REACT_APP_TEMP_PASSWORD; // Replace with your static password
    const correctPassword = process.env.REACT_APP_CORRECT_PASSWORD; // Replace with your static password
    const storedClientId = process.env.REACT_APP_SECRET_CLIENT_ID;  
    const handlePasswordSubmitInternal = (email) => {
      const newSessionId = uuidv4();
      const currentTime = Date.now();
      localStorage.setItem("sessionId", newSessionId);
      localStorage.setItem("sessionCreationTime", currentTime.toString());
      localStorage.setItem("email", email);
      setEmail(email);
      onPasswordSubmit(email);
      window.location.reload();
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === correctPassword || password === anotherPassword) {          
        localStorage.removeItem('conversation')
        onPasswordSubmit(email);
        } else {
        alert('Incorrect password, please try again.');
        }
    };
    const handleGoogleSuccess = (response) => {
      const userObject = jwt_decode(response.credential); 
      console.log(userObject)
      if (userObject && userObject.email) {
        handlePasswordSubmitInternal(userObject.email);
      }
    };
  
    const handleGoogleError = () => {
      console.error("Google Login Failed");
    };


    const subject = encodeURIComponent('Request for password');
    const body = encodeURIComponent('Please provide me the password to access the chatmro.');
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=balbir@raptorsupplies.com&su=${subject}&body=${body}`;
  
    return (
        <div className="overlay">
        <div className="overlay-box">
            <form onSubmit={handleSubmit}>
            <div>
                <label>Use your email and password to login..</label>
                <input
                className='form-control'
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Enter your email'
                required
                />
                <input
                className='form-control'
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
                />
            </div>
            <button type="submit">Submit</button>
            </form>
            <div className="mt-3">
              <GoogleOAuthProvider clientId={storedClientId}>
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleError}
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className="btn btn-danger mt-3"
                    >
                      <i className="fab fa-google"></i> Sign in with Google
                    </button>
                  )}
                />
              </GoogleOAuthProvider>
            </div>

            <div className="mt-3">
            <a href={gmailUrl} target="_blank" rel="noopener noreferrer" className="btn btn-link">
            Contact Us
          </a>
        </div>
        </div>
        </div>
    );
    };

    export default OverlayMessage;
